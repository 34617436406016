@import '../../global/styles/lib';


.cd-credit {
    $text-offset: 100%;
    $transition-on: opacity .8s ease-in-out;
    $transition-off: opacity 3s ease-out;

    display: block;
    position: relative;
    width: 4rem;
    opacity: .4;
    transition: $transition-off;
    padding: 1rem;
    margin: auto;
    margin-top: 1rem;
    z-index: 2;

    &:before,
    &:after {
        display: block;
        position: absolute;
        color: rgba($white, 0.75);
        top: 50%;
        opacity: 0;
        pointer-events: none;
        transform: translateY(-28%);
        transition: $transition-off;
        font-size: .75rem;
        font-weight: 900;
        letter-spacing: rem-calc(1);
        line-height: 1;
        white-space: nowrap;
    }
    &:before {
        right: $text-offset;
        content: 'POWERED BY';
        color: $white;
    }
    &:after {
        left: $text-offset;
        content: 'CHATMAN DESIGN';
        color: $white;
    }

    &:hover,
    &:active {
        opacity: 1;
        transition: $transition-on;
        animation: none;

        &:before,
        &:after {
            opacity: 1;
            pointer-events: auto;
            transition: $transition-on;
        }
    } // &:hover, &:active

} // .cd-credit